import {request2, stripSkip} from "./util";

const authMiddleware: RequestMiddleWare<{status:number, message:string}> = async (req, next) => {
    if (typeof window === 'undefined') {
        req.url = process.env.REACT_APP_URL_BASE + req.url
    }
    let wd = window as any as {$token:string}
    if (!req.headers) req.headers = {}
    req.headers['Access-Token'] = wd.$token
    const resp = await next!(req)
    if (resp.parsedPayload) {
        let respObj = resp.parsedPayload
        if (respObj.status != 0) {
            console.log(respObj.message)
        }
    } else {
        resp.parsedPayload = {status:-1,message:'网络开了小差'}
    }
    return resp
}

export async function postBasic(url='',param={},data:any,header={}) {
    const processedData = stripSkip(data)
    const resp = await request2({url, param, data:processedData, method:'POST'},[authMiddleware])
    return resp.parsedPayload
}

export async function getBasicRaw(url='',param={}) {
    const resp = await request2({url, param, method:'GET',body:null},[authMiddleware])
    return resp.parsedPayload
}
