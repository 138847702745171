import {postBasic,getBasicRaw} from "./api";
import {notification} from "antd";
import {stripSkip} from "./util";
import {provinceVToL, cityLookup, areaLookup, cityVToL, areaVToL} from './constant'
export async function dingLogin(code){
    const pl = {
        code,
        appid: 1
    } as any;
    if (process.env.REACT_APP_USER_ID) {
        pl.jobnumber = process.env.REACT_APP_USER_ID
    }
    return await getBasicRaw('/clearingapi/v1/auth/ding/login',pl)
}
export async function sendSms(phone:string){
    const pl = {
        phone
    }
    return await getBasicRaw('/clearingapi/v1/auth/phone/code/sms',pl)
}
export async function smsLogin(code:string){
    const pl = {
        code,
        appid:1
    }
    return await getBasicRaw('/clearingapi/v1/auth/device/code/login',pl)
}
export async function getCashReturnList(page,pageSize,condition){
    const pl = {
        page,
        pageSize
    }
    const res:PagedVO<CashReturn> = await postBasic('/clearingapi/v1/cashreturn/list',pl,condition)
    return res
}

export async function batchAddCashReturn(pl:CashReturn[]){
    const res:MessageVO = await postBasic('/clearingapi/v1/cashreturn/batch/add',{},pl)
    return res
}

export async function getAdminMemberList(page,pageSize){
    const pl = {
        page,
        pageSize
    }
    const res:PagedVO<AdminMember> = await getBasicRaw('/clearingapi/v1/adminmember/list',pl)
    return res
}
export async function deleteAdminMember(record:AdminMember){
    const pl = {
        id:record.eamId
    }
    const res:MessageVO = await getBasicRaw('/clearingapi/v1/adminmember/delete',pl)
    return res
}
export async function batchAddAdminMember(pl:AdminMember[]){
    const res:MessageVO = await postBasic('/clearingapi/v1/adminmember/batch/add',{},pl)
    return res
}
export async function addAdminMember(pl:AdminMember){
    const res:MessageVO = await postBasic('/clearingapi/v1/adminmember/add',{},pl)
    return res
}
export async function updateAdminMember(pl:AdminMember){
    const res:MessageVO = await postBasic('/clearingapi/v1/adminmember/update',{},pl)
    return res
}

export async function addMapRelation(pl:MapRelation){
    const res:MessageVO = await postBasic('/clearingapi/v1/staffProvinceChannel/addOrModify',{},pl)
    return res
}
export async function updateRecordArea(pl:AdminMember){
    const res:MessageVO = await postBasic('/clearingapi/v1/channel/admin/checkin/county/modify',{},pl)
    return res
}

export async function getNameByEmpNo(empNoList:string[]){
    const res:SimpleObjVO<string[]> = await postBasic('/clearingapi/v1/person/name/by/no',{},empNoList)
    return res
}
export async function getOrderList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<Order> = await postBasic('/clearingapi/v1/order/list',param,condition)
    return res
}
export async function getOrderSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:SimpleListVO<Order> = await postBasic('/clearingapi/v1/order/slice',param,condition)
    return res
}
export async function getUserPageList(){
    const res:SimpleListVO<string> = await getBasicRaw('/clearingapi/v1/rbac/user/page/list', {})
    return res
}
export async function getUserRoleList(){
    const res:SimpleListVO<string> = await getBasicRaw('/clearingapi/v1/rbac/user/role/list', {})
    return res
}
export async function getActionLogList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<ActionLog> = await postBasic('/clearingapi/v1/actionlog/list',param,condition)
    return res
}
export async function getOrderCount(condition){
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/order/count',{},condition)
    return res
}
export async function postBatchUpdate(){
    const res:MessageVO = await postBasic('/clearingapi/v1/order/batch/update/log',{},{})
    return res
}
export async function updateOrder(pl:Order){
    const res:MessageVO = await postBasic('/clearingapi/v1/order/update',{},pl)
    return res
}
export async function updateOrderReason(pl: { id:number, reason:string }[]){
    const res:MessageVO = await postBasic('/clearingapi/v1/order/reason/update',{},pl)
    return res
}
export async function getParticipantCount(){
    const res:SimpleObjVO<number> = await getBasicRaw('/qh5api/v1/participant/count')
    return res
}
export async function getTypeCount() {
    const res:SimpleListVO<{type:string,count:number}> = await getBasicRaw('/qh5api/v1/type/count')
    return res
}

export async function getCheckinRecordList(page,pageSize,condition){//签到结果列表接口
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/list',param,c_data)
    return res
}
export async function getCheckinRecordglList(page,pageSize,condition){//签到结果列表接口
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/phototherapy/admin/checkin/list',param,c_data)
    return res
}


export async function getDetailTabList(page,pageSize,condition){//
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/daily/details',param,c_data)
    return res
}
export async function getStatisList(page,pageSize,condition){//
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/total/statistics',param,c_data)
    return res
}

export async function getCheckinChannelList(page,pageSize,condition){//商务签到结果列表接口
    const param = {
        page,
        pageSize
    }
    if(condition.qudao){
        condition.channel=condition.qudao
        delete condition.qudao
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInChannelV2> = await postBasic('/clearingapi/v1/channel/admin/checkin/list',param,c_data)
    return res
}
export async function getChanneWeeklList(page,pageSize,condition){//商务签到结果列表接口
    const param = {
        page,
        pageSize
    }
    if(condition.qudao){
        condition.channel=condition.qudao
        delete condition.qudao
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)

    const res:PagedVO<ChannelWeekV> = await postBasic('/clearingapi/v1/channel/admin/checkin/stat/week',param,c_data)
    return res
}
export async function getChanneMonthlList(page,pageSize,condition){//商务签到结果列表接口
    const param = {
        page,
        pageSize
    }
    if(condition.qudao){
        condition.channel=condition.qudao
        delete condition.qudao
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<ChannelWeekV> = await postBasic('/clearingapi/v1/channel/admin/checkin/stat/month',param,c_data)
    return res
}


export async function getRecordStoreList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<RecordStore> = await postBasic('/clearingapi/v1/recordstore/list',param,c_data)
    return res
}
export async function getRecordCompany(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<RecordStore> = await postBasic('/clearingapi/v1/recordstore/channel/list',param,c_data)
    return res
}
export async function getRecordAreaList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const newcod={
        oldProvince:condition.province,
        oldCity:condition.city,
        oldCounty:condition.county
    }
    const c_data=stripSkip(newcod)
    const res:PagedVO<RecordArea> = await postBasic('/clearingapi/v1/recordstore/channel/county/list',param,c_data)
    return res
}

export async function getChannelRecordStoreList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<RecordLevel> = await postBasic('/clearingapi/v1/agreementChannel/list',param,c_data)
    return res
}
export async function getChannelMapRelationList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    // const c_data=stripSkip(condition)
    const res:any = await postBasic('/clearingapi/v1/staffProvinceChannel/list',param,{})
    return res
}

export async function getStoreList(page,pageSize){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<CheckinStore> = await getBasicRaw('/clearingapi/v1/checkin/store/list',param)
    return res
}
export async function addCheckinStore(row:CheckinStore){
    const res:MessageVO = await postBasic('/clearingapi/v1/checkin/store/add',{},row)
    return res
}
export async function getCheckinCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/admin/checkin/count',{},condition)
    return res
}
export async function getCheckinglCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/phototherapy/admin/checkin/count',{},condition)
    return res
}

export async function getStatisCount(condition){//
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/admin/checkin/total/statistics/count',{},condition)
    return res
}

export async function getDetailTabCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/admin/checkin/daily/details/count',{},condition)
    return res
}
export async function getChannelCount(condition){
    if(condition.qudao){
        condition.channel=condition.qudao
        delete condition.qudao
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/channel/admin/checkin/count',{},condition)
    return res
}
export async function getChannelWeekCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/channel/admin/checkin/stat/week/count',{},condition)
    return res
}
export async function getChannelMonthCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/channel/admin/checkin/stat/month/count',{},condition)
    return res
}

export async function getStatisSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/total/statistics/slice',param,condition)
    return res
}

export async function getDetailTabSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/daily/details/slice',param,condition)
    return res
}

export async function getCheckinSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/slice',param,condition)
    return res
}
export async function getCheckinglSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/phototherapy/admin/checkin/slice',param,condition)
    return res
}
export async function getChannelSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.qudao){
        condition.channel=condition.qudao
        delete condition.qudao
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInChannelV2> = await postBasic('/clearingapi/v1/channel/admin/checkin/slice',param,condition)
    return res
}
export async function getChannelWeekSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInChannelV2> = await postBasic('/clearingapi/v1/channel/admin/checkin/stat/week/slice',param,condition)
    return res
}
export async function getChannelMonthSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInChannelV2> = await postBasic('/clearingapi/v1/channel/admin/checkin/stat/month/slice',param,condition)
    return res
}
export async function batchAddRecordStore(pl:RecordStore[]){
    const res:MessageVO = await postBasic('/clearingapi/v1/recordstore/batch/add',{},pl)
    return res
}
export async function batchAddRecordCompany(pl:RecordStore[]){
    const res:MessageVO = await postBasic('/clearingapi/v1/recordstore/channel/batch/add',{},pl)
    return res
}

export async function channelBatchAddRecordStore(pl:RecordStore[]){
    const res:MessageVO = await postBasic('/clearingapi/v1/agreementChannel/batch/add',{},pl)
    return res
}
export async function channelBatchAddMapRelation(pl:RecordStore[]){

    const res:MessageVO = await postBasic('/clearingapi/v1/staffProvinceChannel/batch/add',{},pl)
    if(res.status==-1){
        notification['error']({
            message:res.message
        })
    }
    return res
}

export async function postAddRecordStore(batchNum:number){
    const res:MessageVO = await getBasicRaw('/clearingapi/v1/recordstore/post/upload',{batchNum})
    return res;
}
export async function postAddRecordCompany(batchNum:number){
    const res:MessageVO = await getBasicRaw('/clearingapi/v1/recordstore/channel/post/upload',{batchNum})
    return res;
}

export async function postAddChannelRecordStore(batchNum:number){
    const res:MessageVO = await getBasicRaw('/clearingapi/v1/agreementChannel/batch/add',{batchNum})
    return res;
}
export async function delRepeat(){
    const res:any= await getBasicRaw('/clearingapi/v1/agreementChannel/channel/deleteRepeat',{})
    return res;
}


export async function getrsCitys(province:string){
    const res:any= await getBasicRaw('/clearingapi/v1/agreementChannel/record/city',{province})
    return res
}
export async function getrsCountys(city:string){
    const province=''
    const res:any= await getBasicRaw('/clearingapi/v1/recordstore/channel/record/county',{province,city})
    return res
}

export async function getprovinces(){
    const res:any= await getBasicRaw('/clearingapi/v1/agreementChannel/record/province',{})
    return res
}
export async function getaddprovinces(){
    const res:any= await getBasicRaw('/clearingapi/v1/staffProvinceChannel/province',{})
    return res
}

export async function getchannelqudao(){
    const res:any= await getBasicRaw('/clearingapi/v1/channel/checkinrecord/checkin/channels',{})
    return res
}

export async function getdaqus(channelName:string){
    // if(channelName=='全部'){
    //     channelName=''
    // }
    const res:any= await getBasicRaw('/clearingapi/v1/channel/checkinrecord/checkin/regions',{channelName})
    return res
}

export async function getshengqus(region:string,channel:string){
    const res:any= await getBasicRaw('/clearingapi/v1/channel/checkinrecord/checkin/provinces',{channel,region})
    return res
}
export async function getOtcdaqus(){
    const res:any= await getBasicRaw('/clearingapi/v1/checkinrecord/checkin/regions',{})
    return res
}
export async function getOtcshengqus(region:string){

    const res:any= await getBasicRaw('/clearingapi/v1/checkinrecord/checkin/provinces',{region})
    return res
}
export async function getglAreas(region:string){
     //name: '渠道组', value: '567389654'
     //name: '院端组', value: '567311799'
                             let did=''
                             if(region=='渠道组'){
                                 did='567389654'
                             }else if(region=='院端组'){
                                 did='567311799'
                             }else{
                                 did=''
                             }
    const res:any= await getBasicRaw('/clearingapi/v1/phototherapy/checkinrecord/checkin/areas',{groupCategoryId:did})
    return res
}

//hcy 后台接口========================
export async function getHcyUserdatasList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/hcy/backend/getHcyRecordList',param,c_data)
    return res
}
export async function getDepApi(){

    const res:any= await getBasicRaw('/clearingapi/v1/hcy/backend/getProvinces',{})
    return res
}
export async function getHcyCount(condition){

    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/hcy/backend/export/hcyRecordCount',{},condition)
    return res
}
export async function getHcySlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }

    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/hcy/backend/export/getExportHcyRecordList',param,condition)
    return res
}
export async function getHcysendList(page,pageSize,condition){//签到结果列表接口
    const param = {
        page,
        pageSize
    }

    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/hcy/backend/getSmsList',param,c_data)
    return res
}
export async function getManagerApi(){//

    const res:any= await getBasicRaw('/clearingapi/v1/hcy/backend/getManagerRight',{})
    return res
}
type LocationIds = {provinceId?:number,cityId?:number,districtId?:number}
type LocationNames = {province?:string,city?:string,district?:string}
export const processLocationIds = (condition:any & LocationIds) => {
    condition = stripSkip(condition)
    if (condition.provinceId) {
        condition.province = provinceVToL[condition.provinceId]
        delete condition.provinceId
    }
    if (condition.cityId) {
        condition.city = cityVToL[condition.cityId]
        delete condition.cityId
    }
    if (condition.districtId) {
        condition.district = areaVToL[condition.districtId]
        delete condition.districtId
    }
    return condition
}
export const processLocationNames = (condition:any & LocationNames) => {
    if (condition.province) {
        const finded = Object.entries(provinceVToL).find(([k,v]) => v === condition.province)
        condition.provinceId = finded ? finded[0] : 0
        delete condition.province
    }
    if (condition.city) {
        const finded = Object.entries(cityVToL).find(([k,v]) => v === condition.city)
        condition.cityId = finded ? finded[0] : 0
        delete condition.city
    }
    if (condition.district) {
        const finded = Object.entries(areaVToL).find(([k,v]) => v === condition.district)
        condition.districtId = finded ? finded[0] : 0
        delete condition.district
    }
    return condition
}
export const getMembershipCount =  async (condition:MembershipInfoQueryParamDTO) => {
    const res = await postBasic('/clearingapi/v1/admin/membership/count', {}, processLocationIds(condition))
    return res as SimpleObjVO<number>
}

export const getMembershipList =  async (page, pageSize, condition:MembershipInfoQueryParamDTO) => {
    const param = {
        page,
        pageSize
    }
    const res = await postBasic('/clearingapi/v1/admin/membership/list', param, processLocationIds(condition))
    return res as PagedVO<MembershipInfo>
}
export const getMembershipSlice =  async (page, pageSize, condition:MembershipInfoQueryParamDTO) => {
    const param = {
        page,
        pageSize
    }
    const res = await postBasic('/clearingapi/v1/admin/membership/slice', param, processLocationIds(condition))
    return res as SimpleListVO<MembershipInfo>
}
export const updateMembership =  async (info:MembershipInfo) => {
    const id = info.id
    const res = await postBasic(`/clearingapi/v1/admin/membership/update`, {id}, processLocationIds(info))
    return res as MessageVO
}

export const deleteMembership =  async (id) => {
    const res = await postBasic(`/clearingapi/v1/admin/membership/delete`, {id}, {})
    return res as MessageVO
}

// batch add
export const batchAddMembership =  async (info:MembershipInfo[]) => {
    const res = await postBasic(`/clearingapi/v1/admin/membership/batch/add`, {}, info)
    return res as MessageVO
}

export async function getStoreinventorydetails(page,pageSize,condition){//
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/indicator/details',param,c_data)
    return res
}

export async function getpinguis(){
    const res:any= await getBasicRaw('/clearingapi/v1/checkinrecord/checkin/products',{})
    return res
}

export async function getStoreinventoryCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/admin/checkin/indicator/details/count',{},condition)
    return res
}

export async function getStoreinventorySlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/indicator/details/slice',param,condition)
    return res
}

export async function getStoreinventorySummary(page,pageSize,condition){//
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/indicator/total/statistics',param,c_data)
    return res
}

export async function getStoreinventorySummaryCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/admin/checkin/indicator/total/statistics/count',{},condition)
    return res
}
export async function getStoreinventorySummarySlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/indicator/total/statistics/slice',param,condition)
    return res
}