import {
    AppstoreOutlined,
    PieChartOutlined,
    DesktopOutlined,
    CloudSyncOutlined,
    ContainerOutlined,
    ExclamationCircleOutlined,
    ShopOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
export const configBeforeLogin:RouteConfig = {
    path:'/',
    name:'root',
    title:'',
    redirect:'/login',
    children:[
        {
            path:'/login',
            name:'login',
            title:'登录页',
            component:()=>import('../views/before-login/Login')
        }
    ]
}

export const configAfterLogin:RouteConfig = {
    path:'/',
    title:'',
    name:'root',
    redirect:'/welcome',
    children:[
        {
            path:'/welcome',
            name:'welcome',
            title:'欢迎页面',
            icon: AppstoreOutlined,
            component:()=>import('../views/welcome/Index')
        },
        {
            path:'/clearing',
            name:'clearing',
            title:'财务新零售核销',
            icon:ContainerOutlined,
            redirect:'/clearing/order-clearing',
            children:[
                {
                    path:'/clearing/order-clearing',
                    name:'order-clearing',
                    title:'新零售订单核对',
                    icon:PieChartOutlined,
                    component:()=>import('../views/clearing/order-clearing/Index')
                },
                {
                    path:'/clearing/order-clearing-update',
                    name:'order-clearing-update',
                    title:'',
                    notInMenu:true,
                    component:()=>import('../views/clearing/order-clearing/Update')
                },
                {
                    path:'/clearing/admin-member-list',
                    name:'admin-member-list',
                    title:'店铺人员维护',
                    icon:CloudSyncOutlined,
                    component:()=>import('../views/clearing/admin-member/Index')
                },
                {
                    path:'/clearing/admin-member-add',
                    name:'admin-member-add',
                    title:'店铺人员添加',
                    notInMenu:true,
                    component:()=>import('../views/clearing/admin-member/Add')
                },
                {
                    path:'/clearing/admin-member-update',
                    name:'admin-member-update',
                    title:'店铺人员更新',
                    notInMenu:true,
                    component:()=>import('../views/clearing/admin-member/Update')
                },
                {
                    path:'/clearing/cash-flow',
                    name:'cash-flow',
                    title:'银行流水',
                    icon:DesktopOutlined,
                    component:()=>import('../views/clearing/cash-flow/Index')
                },
                {
                    path:'/clearing/action-log',
                    name:'action-log',
                    title:'行为日志',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/clearing/action-log/Index')
                }
            ]
        },
        {
            path:'/qh5',
            name:'qh5',
            title:'体质测试h5',
            icon: AppstoreOutlined,
            redirect:'/qh5/stat',
            children:[
                {
                    path:'/qh5/stat',
                    name:'stat',
                    title:'统计',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/questionh5/Index')
                }
            ]
        },
        {
            path:'/membershipinfo',
            name:'membershipinfo',
            title:'光疗会员信息卡',
            icon: AppstoreOutlined,
            redirect:'/membershipinfo/membershipinfo-list',
            children:[
                {
                    path:'/membershipinfo/membershipinfo-list',
                    name:'membershipinfo-list',
                    title:'列表',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/membershipinfo/Index')
                },
                {
                    path:'/membershipinfo/membershipinfo-update',
                    name:'membershipinfo-update',
                    title:'更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/membershipinfo/Update')
                }
            ]
        },
        //1备案门店 2 签到结果
        {
            path:'/sign',
            name:'sign',
            title:'OTC签到',
            icon: AppstoreOutlined,
            redirect:'/sign/record-store',
            children:[
                {
                    path:'/sign/store',
                    name:'store',
                    title:'办事处维护',
                    icon:ShopOutlined,
                    component:()=>import('../views/sign/store/Index')
                },
                {
                    path:'/sign/store-add',
                    name:'store-add',
                    title:'办事处添加',
                    notInMenu: true,
                    icon:ShopOutlined,
                    component:()=>import('../views/sign/store/Add')
                },
                {
                    path:'/sign/record-store',
                    name:'record-store',
                    title:'备案门店',
                    icon:ShopOutlined,
                    component:()=>import('../views/sign/record-store/Index')
                },
                {
                    path:'/sign/sign-results',
                    name:'sign-results',
                    title:'签到结果',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/sign/sign-results/Index')
                },
                {
                    path:'/sign/geo-util',
                    name:'geo-util',
                    title:'经纬度工具',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/sign/geoutil/Index')
                }
                ,{
                    path:'/sign/sign-statistica',
                    name:'sign-statistica',
                    title:'OTC签到汇总统计表',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/sign/sign-statistica/Index')
                }
                ,{
                    path:'/sign/sign-detailTable',
                    name:'sign-detailTable',
                    title:'OTC签到明细表',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/sign/sign-detailTable/Index')
                },{
                    path:'/sign/storeInventory-summary',
                    name:'storeInventory-summary',
                    title:'OTC门店进销存汇总表',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/sign/storeInventory-summary/Index')
                },{
                    path:'/sign/storeInventory-detail',
                    name:'storeInventory-detail',
                    title:'OTC门店进销存明细表',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/sign/storeInventory-detail/Index')
                }
            ]
        },
        {//商务签到后台
            path:'/channel',
            name:'channel',
            title:'商务签到',
            icon: AppstoreOutlined,
            redirect:'/channel/record-channel',
            children:[
                {
                    path:'/channel/channel-record',
                    name:'channel-record',
                    title:'日明细',
                    icon:ShopOutlined,
                    component:()=>import('../views/channel/channel-record/Index')
                },
                {
                    path:'/channel/channel-week',
                    name:'channel-week',//
                    title:'周报表',
                    icon:ShopOutlined,
                    component:()=>import('../views/channel/channel-week/Index')
                },
                {
                    path:'/channel/channel-month',
                    name:'channel-month',
                    title:'月报表',
                    icon:ShopOutlined,
                    component:()=>import('../views/channel/channel-month/Index')
                },
                {
                    path:'/channel/channel-level',
                    name:'channel-level',
                    title:'备案商业信息',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/channel/channel-level/Index')
                },
                {
                    path:'/channel/channel-mapRelation',
                    name:'channel-mapRelation',
                    title:'各省区人员映射维护表',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/channel/channel-mapRelation/Index')
                },
                {
                    path:'/channel/channel-mapRelation-add',
                    name:'channel-mapRelation-add',
                    title:'新增',
                    notInMenu:true,
                    component:()=>import('../views/channel/channel-mapRelation/Add')
                },
                {
                    path:'/channel/channel-mapRelation-update',
                    name:'channel-mapRelation-update',
                    title:'修改',
                    notInMenu:true,
                    component:()=>import('../views/channel/channel-mapRelation/Update')
                },
                {
                    path:'/channel/channel-recordCompany',
                    name:'channel-recordCompany',
                    title:'备案商业公司',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/channel/channel-recordCompany/Index')
                },
                {
                    path:'/channel/channel-recordAreaList',
                    name:'channel-recordAreaList',
                    title:'备案区域列表',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/channel/channel-recordAreaList/Index')
                },{
                    path:'/channel/channel-recordArea-update',
                    name:'channel-recordArea-update',
                    title:'备案区域修改',
                    notInMenu:true,
                    component:()=>import('../views/channel/channel-recordAreaList/Update')
                },
            ]
        },
        {
            path:'/phototherapy',
            name:'phototherapy',
            title:'光疗签到',
            icon: AppstoreOutlined,
            redirect:'/phototherapy/phototherapy-record',
            children:[

                {
                    path:'/phototherapy/phototherapy-record',
                    name:'phototherapy-record',
                    title:'签到结果',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/phototherapy/phototherapy-record/Index')
                }
            ]
        },
        {
            path:'/hcy',
            name:'hcy',
            title:'hcy检测系统',
            icon: AppstoreOutlined,
            redirect:'/hcy/hcy-userdatas',
            children:[

                {
                    path:'/hcy/hcy-userdatas',
                    name:'hcy-userdatas',
                    title:'用户数据',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/hcy/hcy-userdatas/Index')
                },{
                    path:'/hcy/hcy-sendrecord',
                    name:'hcy-sendrecord',
                    title:'发送记录',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/hcy/hcy-sendrecord/Index')
                }

            ]
        },

    ]
}
export const configNoop:RouteConfig = {
    path:'/',
    name:'noop',
    title:'',
    children:[]
}
